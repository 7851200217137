<template>
    <div class="md:flex flex-col md:flex-row md:min-h-screen w-full" id="menu">

        <div class="flex flex-col w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 relative">

            <div class="bg-terciary text-black hidden md:flex justify-center items-center h-16 rounded-r-xl absolute top-80 2xl:top-96 -right-3" @click="$store.commit('setMenuTotal')" :style="{ 'background-color': $store.state.colorSecundaria, 'color': invertColor($store.state.colorSecundaria, true) }">
                <svg v-if="!$store.state.menuTotal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                </svg>
                <svg v-if="$store.state.menuTotal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                </svg>

            </div>

            <nav :class="{'block': $store.state.openMobileNav, 'hidden': !$store.state.openMobileNav, }" class="flex-grow md:block px-2 pb-4 md:pb-0 md:overflow-y-auto" :style="{ 'background-color': $store.state.colorPrincipal }">
                <div class="relative 2xl:ml-1" :class="{'text-center': !$store.state.menuTotal}">
                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner, 'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/painel" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M204.23,83.5A107.37,107.37,0,0,0,127.62,52C68.28,52.21,20,101.16,20,161.13V184a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V160A107.25,107.25,0,0,0,204.23,83.5ZM228,184a4,4,0,0,1-4,4H111.85l59.38-81.65a4,4,0,1,0-6.46-4.7L102,188H32a4,4,0,0,1-4-4V161.13A103.42,103.42,0,0,1,28.84,148H56a4,4,0,0,0,0-8H30.21C39.59,95.66,77.84,61.93,124,60.09V88a4,4,0,0,0,8,0V60.08A100.08,100.08,0,0,1,226,140H200a4,4,0,0,0,0,8h27.29a101.6,101.6,0,0,1,.71,12Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Painel de controle' : '' }}
                    </router-link>
                        
                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/cadastros" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M223.51,52h-191A12.5,12.5,0,0,0,20,64.49v127A12.5,12.5,0,0,0,32.49,204h191A12.5,12.5,0,0,0,236,191.51v-127A12.5,12.5,0,0,0,223.51,52ZM228,191.51a4.49,4.49,0,0,1-4.49,4.49h-191A4.49,4.49,0,0,1,28,191.51v-127A4.49,4.49,0,0,1,32.49,60h191A4.49,4.49,0,0,1,228,64.49ZM204,128a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,128Zm0-32a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,96ZM68,160a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8h8A4,4,0,0,1,68,160Zm96,0a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,160Zm40,0a4,4,0,0,1-4,4h-8a4,4,0,0,1,0-8h8A4,4,0,0,1,204,160Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Cadastros gerais' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/entregaveis" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }"> 
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M210.83,85.17l-56-56A4,4,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A4,4,0,0,0,210.83,85.17ZM156,41.65,198.34,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Documentos e entregáveis' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/projetos" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M92,144V128a4,4,0,0,1,8,0v16a4,4,0,0,1-8,0Zm36,4a4,4,0,0,0,4-4V120a4,4,0,0,0-8,0v24A4,4,0,0,0,128,148Zm32,0a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,160,148Zm52-72V180h12a4,4,0,0,1,0,8H132v24.4a20,20,0,1,1-8,0V188H32a4,4,0,0,1,0-8H44V76H40A12,12,0,0,1,28,64V48A12,12,0,0,1,40,36H216a12,12,0,0,1,12,12V64a12,12,0,0,1-12,12ZM128,220a12,12,0,1,0,12,12A12,12,0,0,0,128,220ZM40,68H216a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V64A4,4,0,0,0,40,68Zm164,8H52V180H204Z"></path></svg>
                        {{ $store.state.user.cliente ? $store.state.menuTotal ? 'Projeto / Programa' : '': $store.state.menuTotal ? 'Gestão de projetos' : '' }}
                    </router-link>
                    
                    <h4  v-if="!$store.state.user.cliente" class="text-white text-normal font-bold transform -rotate-90 absolute inset-y-2/4 uppercase" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'left': '-1.9rem'}">Início</h4>
                </div>

                <hr v-if="!$store.state.user.cliente" class="mt-4 border-gray-700" />

                <div class="relative 2xl:ml-1" :class="{'text-center': !$store.state.menuTotal}">
                    <router-link v-if="!$store.state.user.cliente" :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/diagnosticos" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M212,60a24,24,0,1,0,24,24A24,24,0,0,0,212,60Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,100Zm-88,56.29V88a4,4,0,0,0-8,0v68.29a28,28,0,1,0,8,0ZM120,204a20,20,0,1,1,20-20A20,20,0,0,1,120,204Zm36-68V48a36,36,0,0,0-72,0v88a60,60,0,1,0,72,0ZM120,236a52,52,0,0,1-29.71-94.68A4,4,0,0,0,92,138V48a28,28,0,0,1,56,0v90a4,4,0,0,0,1.71,3.28A52,52,0,0,1,120,236Z"></path></svg>
                        Diagnósticos
                    </router-link>
                    <router-link v-else :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/diagnosticos/questionarios" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M212,60a24,24,0,1,0,24,24A24,24,0,0,0,212,60Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,100Zm-88,56.29V88a4,4,0,0,0-8,0v68.29a28,28,0,1,0,8,0ZM120,204a20,20,0,1,1,20-20A20,20,0,0,1,120,204Zm36-68V48a36,36,0,0,0-72,0v88a60,60,0,1,0,72,0ZM120,236a52,52,0,0,1-29.71-94.68A4,4,0,0,0,92,138V48a28,28,0,0,1,56,0v90a4,4,0,0,0,1.71,3.28A52,52,0,0,1,120,236Z"></path></svg>
                        Questionários
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/diagnosticos-gerais" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M212,60a24,24,0,1,0,24,24A24,24,0,0,0,212,60Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,100Zm-88,56.29V88a4,4,0,0,0-8,0v68.29a28,28,0,1,0,8,0ZM120,204a20,20,0,1,1,20-20A20,20,0,0,1,120,204Zm36-68V48a36,36,0,0,0-72,0v88a60,60,0,1,0,72,0ZM120,236a52,52,0,0,1-29.71-94.68A4,4,0,0,0,92,138V48a28,28,0,0,1,56,0v90a4,4,0,0,0,1.71,3.28A52,52,0,0,1,120,236Z"></path></svg>
                        {{ $store.state.user.cliente ? $store.state.menuTotal ? 'Questionários de diagnósticos' : '': $store.state.menuTotal ? 'Diagnósticos gerais' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" to="/treinamentos" @click.native="$store.commit('setOpenMobileNav')" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M225.27,60.21l-96-32a4,4,0,0,0-2.54,0l-96,32A4,4,0,0,0,28,64v80a4,4,0,0,0,8,0V69.55L79.88,84.18a60,60,0,0,0,24.54,91c-20.86,5.74-39,19.13-51.77,38.65a4,4,0,0,0,6.7,4.36C75.17,193.92,100.2,180,128,180s52.83,13.92,68.65,38.18a4,4,0,0,0,6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60,60,0,0,0,24.54-91l49.15-16.39a4,4,0,0,0,0-7.58ZM180,120A52,52,0,1,1,87.93,86.86l38.8,12.93a3.95,3.95,0,0,0,2.54,0l38.8-12.93A51.85,51.85,0,0,1,180,120ZM128,91.78,44.65,64,128,36.22,211.35,64Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de treinamentos' : '' }}
                    </router-link> 

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/mapeamento" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M128,68a36,36,0,1,0,36,36A36,36,0,0,0,128,68Zm0,64a28,28,0,1,1,28-28A28,28,0,0,1,128,132Zm0-112a84.09,84.09,0,0,0-84,84c0,30.42,14.17,62.79,41,93.62a250,250,0,0,0,40.73,37.66,4,4,0,0,0,4.58,0A250,250,0,0,0,171,197.62c26.81-30.83,41-63.2,41-93.62A84.09,84.09,0,0,0,128,20Zm37.1,172.23A254.62,254.62,0,0,1,128,227a254.62,254.62,0,0,1-37.1-34.81C73.15,171.8,52,139.9,52,104a76,76,0,0,1,152,0C204,139.9,182.85,171.8,165.1,192.23Z"></path></svg>
                        {{ $store.state.user.cliente ? $store.state.menuTotal ? 'Questionários de mapeamentos': '' : $store.state.menuTotal ? 'Mapeamento de dados': '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/riscos" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M92,132H40a12,12,0,0,1-8.81-3.91,12.2,12.2,0,0,1-3.14-9.31A99.82,99.82,0,0,1,70,46.2,12,12,0,0,1,87.29,50l25.94,45a4,4,0,0,1-6.92,4L80.36,54a4,4,0,0,0-5.78-1.27A91.83,91.83,0,0,0,36,119.5a4.2,4.2,0,0,0,1.08,3.2A3.9,3.9,0,0,0,40,124H92a4,4,0,0,1,0,8Zm136-13.22A99.82,99.82,0,0,0,186,46.2,12,12,0,0,0,168.72,50L142.77,95a4,4,0,0,0,6.92,4L175.64,54a4,4,0,0,1,5.78-1.27A91.83,91.83,0,0,1,220,119.5a4.2,4.2,0,0,1-1.08,3.2A3.9,3.9,0,0,1,216,124H164a4,4,0,0,0,0,8h52a12,12,0,0,0,8.81-3.91A12.2,12.2,0,0,0,228,118.78Zm-78.48,38.4a4,4,0,0,0-6.93,4l25.82,44.73a4,4,0,0,1,.35,3.22,3.9,3.9,0,0,1-2.12,2.41,92.24,92.24,0,0,1-77.18,0,3.9,3.9,0,0,1-2.12-2.41,4,4,0,0,1,.35-3.22l25.82-44.73a4,4,0,0,0-6.93-4L80.71,201.91a12,12,0,0,0,5.34,16.89,100.24,100.24,0,0,0,83.9,0,12,12,0,0,0,5.34-16.89ZM128,136a8,8,0,1,0-8-8A8,8,0,0,0,128,136Z"></path></svg>
                        {{ $store.state.user.cliente ? $store.state.menuTotal ? 'Questionários de riscos': '' : $store.state.menuTotal ? 'Gestão de risco': '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" to="/contratos" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M100,104a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H104A4,4,0,0,1,100,104Zm4,36h64a4,4,0,0,0,0-8H104a4,4,0,0,0,0,8Zm124,52a28,28,0,0,1-28,28H88a28,28,0,0,1-28-28V64a20,20,0,0,0-40,0c0,7.78,6.34,12.75,6.4,12.8a4,4,0,1,1-4.8,6.4C21.21,82.91,12,75.86,12,64A28,28,0,0,1,40,36H176a28,28,0,0,1,28,28V172h12a4,4,0,0,1,2.4.8C218.79,173.09,228,180.14,228,192Zm-120,0c0-7.78-6.34-12.75-6.4-12.8A4,4,0,0,1,104,172h92V64a20,20,0,0,0-20-20H59.57A27.9,27.9,0,0,1,68,64V192a20,20,0,0,0,40,0Zm112,0c0-6-3.74-10.3-5.5-12H112.61A23.31,23.31,0,0,1,116,192a27.94,27.94,0,0,1-8.42,20H200A20,20,0,0,0,220,192Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de contratos' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="$store.state.user.cliente" active-class="border rounded-xl" to="/treinamentos-list" @click.native="$store.commit('setOpenMobileNav')" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H53.39A4,4,0,0,0,57,209.71a52,52,0,0,1,94,0,4,4,0,0,0,3.61,2.29H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H157.08a60.38,60.38,0,0,0-34.68-29.07,36,36,0,1,0-36.8,0A60.38,60.38,0,0,0,50.92,204H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM104,172a28,28,0,1,1,28-28A28,28,0,0,1,104,172Zm92-92v96a4,4,0,0,1-4,4H176a4,4,0,0,1,0-8h12V84H68V96a4,4,0,0,1-8,0V80a4,4,0,0,1,4-4H192A4,4,0,0,1,196,80Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Eventos / Treinamentos' : '' }}
                    </router-link>
                    
                    <h4  v-if="!$store.state.user.cliente" class="text-white text-normal font-bold transform -rotate-90 absolute inset-y-2/4 uppercase" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'left': '-6.1rem'}">Construir e Atualizar</h4>
                </div>

                <hr v-if="!$store.state.user.cliente" class="mt-4 border-gray-700" />

                <div class="relative 2xl:ml-1" :class="{'text-center': !$store.state.menuTotal}">
                    <router-link :class="{'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/cookies" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M161.66,166.34a8,8,0,1,1-11.32,0A8,8,0,0,1,161.66,166.34Zm-75.32-8a8,8,0,1,0,11.32,0A8,8,0,0,0,86.34,158.34Zm3.32-56a8,8,0,1,0,0,11.32A8,8,0,0,0,89.66,102.34Zm36.68,16a8,8,0,1,0,11.32,0A8,8,0,0,0,126.34,118.34ZM228,128A100,100,0,1,1,128,28a4,4,0,0,1,4,4,44.05,44.05,0,0,0,44,44,4,4,0,0,1,4,4,44.05,44.05,0,0,0,44,44A4,4,0,0,1,228,128Zm-8.08,3.84a52.08,52.08,0,0,1-47.78-48,52.08,52.08,0,0,1-48-47.78,92,92,0,1,0,95.76,95.76Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de cookies' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/requisicao" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M153.76,28.41a4,4,0,0,0-4.22.43L78.63,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.63l70.91,55.16A4.07,4.07,0,0,0,152,228a3.92,3.92,0,0,0,1.76-.41A4,4,0,0,0,156,224V32A4,4,0,0,0,153.76,28.41ZM148,215.82l-65.54-51A4.06,4.06,0,0,0,80,164H32a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H80a4.06,4.06,0,0,0,2.46-.84l65.54-51ZM196,104v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm32-16v80a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de requisições' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" to="/incidentes" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M124,136V80a4,4,0,0,1,8,0v56a4,4,0,0,1-8,0Zm4,28a8,8,0,1,0,8,8A8,8,0,0,0,128,164Zm108-36a11.87,11.87,0,0,1-3.5,8.45l-96.05,96.06a12,12,0,0,1-16.9,0h0l-96-96.06a12,12,0,0,1,0-16.9l96.05-96.06a12,12,0,0,1,16.9,0l96.05,96.06A11.87,11.87,0,0,1,236,128Zm-8,0a3.9,3.9,0,0,0-1.16-2.79L130.79,29.15a4,4,0,0,0-5.58,0l-96,96.06a3.94,3.94,0,0,0,0,5.58l96.05,96.06a4,4,0,0,0,5.58,0l96.05-96.06A3.9,3.9,0,0,0,228,128Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de incidentes' : '' }}
                    </router-link>

                    <router-link :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" v-if="!$store.state.user.cliente" active-class="border rounded-xl" to="/denuncias" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M248,116H218L169.35,49a12,12,0,0,0-19-.51L137.45,63.38l-.1.12a12,12,0,0,1-18.7,0l-.1-.12L105.66,48.44a12,12,0,0,0-19,.51L38,116H8a4,4,0,0,0,0,8H248a4,4,0,0,0,0-8ZM93.13,53.65A4,4,0,0,1,96.26,52a4,4,0,0,1,3.2,1.5l.1.12,12.89,14.94A19.86,19.86,0,0,0,128,76h0a19.86,19.86,0,0,0,15.55-7.44l12.89-14.94.1-.12a4.06,4.06,0,0,1,3.2-1.5,4,4,0,0,1,3.13,1.65L208.15,116H47.85ZM180,148a32,32,0,0,0-32,32H108a32,32,0,1,0-1,8h42a32,32,0,1,0,31-40ZM76,204a24,24,0,1,1,24-24A24,24,0,0,1,76,204Zm104,0a24,24,0,1,1,24-24A24,24,0,0,1,180,204Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Gestão de denúncias' : '' }}
                    </router-link>
                   

                    <h4 v-if="!$store.state.user.cliente" class="text-white text-normal font-bold transform -rotate-90 absolute inset-y-2/4 uppercase" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'left': '-3.3rem' }">Monitorar</h4>
                </div>

                <hr v-if="!$store.state.user.cliente" class="mt-4 border-gray-700" />

                <div v-if="$store.state.user.consultor || $store.state.user.admin" class="relative 2xl:ml-1" :class="{'text-center': !$store.state.menuTotal}">

                    <div :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" class="flex">
                        <a active-class="border rounded-2xl " @click="$modal.show('AvisoEmbreve')" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                            <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M29.6,123.2a4,4,0,0,0,5.6-.8,56,56,0,0,1,89.6,0,3.93,3.93,0,0,0,6.38,0h0a56,56,0,0,1,89.6,0,4,4,0,1,0,6.4-4.8,63.55,63.55,0,0,0-32.5-22.85,36,36,0,1,0-37.4,0,63.39,63.39,0,0,0-29.3,19,63.34,63.34,0,0,0-29.3-19,36,36,0,1,0-37.4,0A63.61,63.61,0,0,0,28.8,117.6,4,4,0,0,0,29.6,123.2ZM148,64a28,28,0,1,1,28,28A28,28,0,0,1,148,64ZM52,64A28,28,0,1,1,80,92,28,28,0,0,1,52,64ZM194.7,198.75a36,36,0,1,0-37.4,0A63.39,63.39,0,0,0,128,217.7a63.34,63.34,0,0,0-29.3-18.95,36,36,0,1,0-37.4,0A63.61,63.61,0,0,0,28.8,221.6a4,4,0,0,0,6.4,4.8,56,56,0,0,1,89.6,0,3.93,3.93,0,0,0,6.38,0h0a56,56,0,0,1,89.6,0,4,4,0,0,0,6.4-4.8A63.55,63.55,0,0,0,194.7,198.75ZM52,168a28,28,0,1,1,28,28A28,28,0,0,1,52,168Zm96,0a28,28,0,1,1,28,28A28,28,0,0,1,148,168Z"></path></svg>
                            {{ $store.state.menuTotal ? 'Comunidade' : '' }}
                        </a>
                        <span v-if="$store.state.menuTotal" class="bg-red-500 text-xs_0 text-white py-1 px-1 rounded-full h-6 mt-4">Em breve</span>
                    </div>
                    
                    <div :class="{'opacity-20': $store.state.user.cookieBanner,'justify-around': !$store.state.menuTotal }" class="flex">
                        <a active-class="border rounded-xl" @click="$modal.show('AvisoEmbreve')" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                            <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M196,128a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H192A4,4,0,0,1,196,128Zm36-52H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8Zm-80,96H104a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8Z"></path></svg>
                            {{ $store.state.menuTotal ? 'Gestão de clientes' : '' }}
                        </a>
                        <span v-if="$store.state.menuTotal" class="inline-block bg-red-500 text-xs_0 text-white py-1 px-1 rounded-full h-6 mt-4">Em breve</span>
                    </div>
                        
                    <router-link :class="{'justify-around': !$store.state.menuTotal }" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/suporte" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                        <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M216,52H40A12,12,0,0,0,28,64V224a11.89,11.89,0,0,0,6.93,10.88A12.17,12.17,0,0,0,40,236a11.89,11.89,0,0,0,7.69-2.83l.06-.06,32.14-28.17A4,4,0,0,1,82.5,204H216a12,12,0,0,0,12-12V64A12,12,0,0,0,216,52Zm4,140a4,4,0,0,1-4,4H82.5a12.1,12.1,0,0,0-7.79,2.87l-32.16,28.2A4,4,0,0,1,36,224V64a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Zm-84-64a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm-44,0a8,8,0,1,1-8-8A8,8,0,0,1,92,128Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,180,128Z"></path></svg>
                        {{ $store.state.menuTotal ? 'Suporte e Opinião' : '' }}
                    </router-link>

                    <hr class="mt-4 border-gray-700" />

                    <h4 class="text-white text-normal font-bold transform -rotate-90 absolute inset-y-2/4 pl-1" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'left': '-2.3rem' }">EXTRAS</h4>
                </div>

                <router-link v-if="$store.state.user.consultor && !$store.state.user.admin && !$store.state.user.fluxoAssinaturaManual" active-class="border rounded-xl" @click.native="$store.commit('setOpenMobileNav')" to="/assinatura" class="flex items-center px-2 py-1 mt-2 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }">
                    Assinatura
                </router-link>

                <a @click="logout" class="mt-5 mb-5 flex items-center px-2 py-1 mx-3 2xl:mx-4 text-xs_3 2xl:text-sm font-light text-gray-900 bg-transparent dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-gray-900 focus:outline-none focus:shadow-outline" :style="{ 'color': invertColor($store.state.colorPrincipal, true), 'border-color': invertColor($store.state.colorPrincipal, true) }" href="javascript:void(0)">
                    <svg class="mr-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256"><path d="M108,216a4,4,0,0,1-4,4H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36h56a4,4,0,0,1,0,8H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4h56A4,4,0,0,1,108,216Zm110.83-90.83-40-40a4,4,0,0,0-5.66,5.66L206.34,124H104a4,4,0,0,0,0,8H206.34l-33.17,33.17a4,4,0,0,0,5.66,5.66l40-40A4,4,0,0,0,218.83,125.17Z"></path></svg>
                    <b> Sair </b>
                </a>
            </nav>
        </div>
        <modal name="AvisoEmbreve" :adaptive="true" :height="'auto'">
            <div class="bg-gray-200 px-5 py-5">
                <h2 class="text-2xl font-bold mb-3"> Novidades a caminho</h2>
                <p class="text-base font-medium text-gray-900 mb-3">Também estamos ansiosos por este módulo. Em breve ele estará disponível para sua assinatura. </p>
                <button @click="$modal.hide('AvisoEmbreve')" class="text-white hover:bg-gray-500 bg-gray-700 rounded py-1 px-4">
                    OK
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    methods: {
        async logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
        }
    },
}
</script>